<template>
  <div id="generated">
    <loading :active="loading || !quiz" :is-full-page="true"></loading>
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />
    <main class="main-map">
      <div class="page-print" ref="documentprint" id="page-print" v-if="quiz">
        <div class="page-print-page2">
          <div class="page-print-header2">
            <div class="page-print-header2-title">
              <h2>Radar ESG</h2>
            </div>
            <div class="page-print-page2-line">
              <div class="page-print-page2-radar-graph">
                <div class="page-print-page2-radar-cards">
                  <div
                    class="page-print-page2-radar-card"
                    v-for="(eixo, index) in labels"
                    v-bind:key="eixo"
                    :style="{ 'background-color': esgColors[index] }"
                  >
                    <div class="page-print-page2-radar-text">
                      <p>{{ eixo }}</p>
                    </div>
                    <div class="page-print-page2-radar-text">
                      <p>
                        {{
                          esgDataIndividual[index]
                            .toFixed(1)
                            .toString()
                            .replace(".", ",")
                        }}%
                      </p>
                    </div>
                    <div class="page-print-page2-radar-img">
                      <img
                        :src="require('./../images/' + icons[index] + '.png')"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="page-print-page2-radar-chart">
                <div class="page-print-page-radar-chart-items"></div>
                <img
                  class="mostrar-svg"
                  src="../images/esg-icon.svg"
                  v-if="mostrar"
                />
                <canvas class="chart"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="page-print-footer">
          <img
            src="./../images/logo-seall.png"
            alt="Logo SEALL"
            class="img-logo"
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import _ from "lodash";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import AuthService from "../../services/auth/auth";
import OrganizationService from "../../services/organization/organization.service.js";
import "../javascript/chart.bundle.min.js";
import "../javascript/chartjs-plugin-datalabels";
import "../javascript/chartjs-plugin-labels";
export default {
  name: "generated",
  props: {
    print: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      altura: "20vh",
      valor: 4.5,
      valor_map: 4.5,
      valor_graph: 500,
      index: 0,
      mostrar: true,
      loading: false,
      quiz: null,
      axles: [],
      labels: ["Ambiental", "Governança", "Social"],
      icons: ["esg-ambiente", "esg-governanca", "esg-social"],
      esgColors: ["#00B7BB", "#6A11CA", "#E65F0C"],
      esgData: [],
      esgDataIndividual: [],
      langs: [
        ["en", "en-US"],
        ["es", "es-EN"],
        ["pt", "pt-BR"],
      ],
    };
  },
  mounted() {
    this.loading = true;
    this.load();
    if (window.screen.availWidth < 1025) {
      this.valor = 1.25;
      this.valor_map = 1.5;
      this.valor_graph = 350;
    }
  },
  methods: {
    create_pdf() {
      if (this.print) {
        setTimeout(() => {
          window.print();
        }, 1000);
      }
    },
    mounted_chart() {
      this.loading = true;

      const labelImage = [
        {
          src:
            process.env.VUE_APP_FRONTEND +
            require(`./../images/esg-ambiente.png`),
          width: 40,
          height: 40,
        },
        {
          src:
            process.env.VUE_APP_FRONTEND +
            require(`./../images/esg-governanca.png`),
          width: 40,
          height: 40,
        },
        {
          src:
            process.env.VUE_APP_FRONTEND +
            require(`./../images/esg-social.png`),
          width: 40,
          height: 40,
        },
      ];

      const self = this;

      setTimeout(() => {
        this.chart = new Chart(document.getElementsByClassName("chart"), {
          type: "doughnut",
          data: {
            labels: self.labels,
            datasets: [
              {
                data: this.esgData,
                fill: false,
                backgroundColor: self.esgColors,
                borderColor: self.esgColors,
              },
            ],
          },
          options: {
            plugins: {
              datalabels: {
                anchor: "end",
                align: "start",
              },
              labels: {
                render: "image",
                textMargin: -60,
                images: labelImage,
              },
            },
            tooltips: { enabled: false },
            legend: { display: false },
            responsive: false,
            onClick: function (e, activeElement) {
              var bar = this.getElementAtEvent(e)[0];
              var index = bar._index;

              self.chart.fillStyle = self.esgColors[self.index];

              self.mostrar = false;
              self.index = index;

              console.log(self.mostrar, self.index);

              this.update();
            },
          },
        });
        this.loading = false;
        this.create_pdf();
      }, 500);
    },
    load() {
      this.loading = true;
      OrganizationService.getG4Quiz(AuthService.getCogId())
        .then((org) => {
          this.quiz = _.groupBy(org.data, (q) => q.axle);
          this.axles = this.labels;

          console.log(this.quiz, this.axles);

          this.calculateEsgData();
          this.mounted_chart();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$notify({
            title: this.$t("common.error_http"),
            type: "error",
          });
        });
    },
    calculateEsgData() {
      let sumTotal = 0;
      const answerByAxle = [];
      this.axles.forEach((ax) => {
        const questions = this.quiz[ax];
        let sumRating = 0;
        questions.forEach((question) => {
          const answer = question.answer;
          const ratingOfAnswer = question.options.find(
            (opt) => opt.id === answer.answerId
          ).rating;
          sumRating = sumRating + ratingOfAnswer;
        });
        sumTotal = sumTotal + sumRating;
        answerByAxle.push(sumRating);
        this.esgDataIndividual.push((sumRating*100)/(5*questions.length));
      });

      answerByAxle.forEach((a) => {
        this.esgData.push((a * 100) / sumTotal);
      });
    },
  },
  components: {
    Loading,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: Lato-Regular;
  src: url(../fonts/Lato/Lato-Regular.ttf);
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
  .noprint,
  .footer-map {
    display: none;
  }
}
@page {
  size: 42cm 29.7cm;
  margin: 0mm;
}

* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  color-adjust: exact !important; /*Firefox*/
}

html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: #fff;
}

main {
  margin: 0vh 0vw;
  padding-top: 3vh;
}

.chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
  width: 100%;
  height: 100%;
  min-height: 443px;
  min-width: 866px;
  z-index: 10;
}

.checkbox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  background: #fff;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow 0.3s;
  box-shadow: inset 0 0 0 var(--s, 1px) var(--b, #d1d6ee);
}

.header-map {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 3vw;
}

.material-icons {
  color: #000000;
}

.main-map {
  background-color: #ffffff;
}

.footer-map {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}

.footer-map svg {
  width: 5%;
  height: 1vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw 2vh;
}

.footer-map rect {
  width: 100%;
  height: 1vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}

.div-map-footer-button {
  display: flex;
  flex-direction: row;
  padding: 5vh 0vw;
  justify-content: center;
}

.div-map-footer-button .material-icons {
  color: #2f1764;
}

.map-footer-button-back {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2f1764;
  background-color: #ffffff;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 1vw;
  padding: 0vh 1vw;
}

.map-footer-button {
  cursor: pointer;
  color: #ffffff;
  background-color: #2f1764ff;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 0vw 0vh 10vw;
  padding: 1vh 3vw;
}

.page-print {
  margin: 0vh 1vw;
}

.page-print-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0vh 0vw 3vh;
}

.page-print-header2 {
  border: 2px solid #a3a3a3;
  box-sizing: border-box;
  border-radius: 21.7826px;
  padding: 2rem;
  .page-print-header2-title {
    display: flex;
    justify-content: center;
  }
}

.page-print-header-title {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 3vh;
  line-height: 120%;
  text-align: center;
  color: #32363b;
  opacity: 0.8;
}

.page-print-header-iniciative {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 3vh;
  line-height: 120%;
  text-align: center;
  color: #533a8b;
}

.page-print-header-name {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 3vh;
  line-height: 120%;
  text-align: center;
  color: #32363b;
  opacity: 0.8;
}

.page-print-header-pag {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 3vh;
  line-height: 120%;
  text-align: center;
  color: #32363b;
  opacity: 0.8;
}

.page-print-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 2rem 2rem 2rem 5rem;
}

.page-print-footer img {
  width: 7%;
}

.page-print-page1-infos {
  display: flex;
  flex-direction: row;
}
.page-print-page1-infos-orgimg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 15%;
}
.page-print-page1-infos-orgimg img {
  width: 90%;
  border-radius: 50%;
}

.page-print-page1-infos-org {
  padding: 0vh 0vw 0vh 3vw;
  width: 30%;
}

.page-print-page1-infos-mission {
  width: 35%;
}

.page-print-page1-infos-img {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.page-print-page1-infos-img img {
  width: 50%;
}

.page-print-page1 h3 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 120%;
  color: #533a8b;
  margin: 0vh 0vw;
  padding: 5vh 0vw 0vh;
}

.page-print-page1-infos h2 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #687d9b;
  margin: 0vh 0vw;
  padding: 1vh 0vw;
}

.page-print-page1-infos h3 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 120%;
  color: #533a8b;
  margin: 0vh 0vw;
  padding: 1vh 0vw;
}

.page-print-page1-infos h4 {
  font-family: NunitoSans-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 120%;
  color: #32363b;
  margin: 0vh 0vw;
  padding: 1vh 0vw;
}

.page-print-page1-infos span {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  color: #32363b;
  margin: 0vh 0vw;
  padding: 1vh 0vw;
}

.page-print-page1-infos p {
  margin: 0.5vh 0vw;
  padding: 0.5vh 0.5vw;
  background-color: #f7f9fa;
  border: 1px solid #533a8b;
  border-radius: 48px;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #533a8b;
  opacity: 1;
  width: 50%;
}

.page-print-page1-map {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5vh 0vh;
  margin: 0vh 0vw;
  width: 100%;
}

.page-print-page1-map i {
  border-radius: 50%;
}

.map-points-line {
  padding: 0.5vh 0.5vw;
  text-align: left;
  width: 12vw;
}

.map-print-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-height: 70%;
}

.map-print-card-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  background: #fff;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow 0.3s;
  box-shadow: inset 0 0 0 var(--s, 1px) var(--b, #d1d6ee);
}

.map-print-card {
  border: 1px solid #533a8b;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: flex-start;
  background-color: #ffffff;
  margin: 0.5vh 0vw;
  padding: 0vh 0vw;
  width: 11vw;
}

.map-print-card input {
  border-radius: 50%;
}

.page-print-page1-map span {
  width: 100%;
  height: 7vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 170%;
  color: #533a8b;
}

.page-print-page1-map p {
  width: 90%;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 115%;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 0vh 1vw;
  text-align: center;
}

.page-print-page2 h2 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 120%;
  color: #492e85;
}

.page-print-page2-line {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.page-print-page2-radar-graph {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;
  border-radius: 16px;
  padding: 0vh 2vw;
  width: 50%;
}

.page-print-page2-radar-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  text-align: left;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
}

.page-print-page2-radar-card {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1vh 0.5vw;
  margin: 0vh 0.5vw;
  height: 200px;
  border-radius: 10px;
  width: 200px;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.page-print-page2-radar-text {
  display: flex;
  flex-direction: row;
  height: 30%;
  width: 100%;
}

.page-print-page2-radar-card p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: flex-start;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  font-size: 2.5vh;
  height: 100%;
}

.page-print-page2-radar-card span {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  justify-items: center;
  padding: 7px 0 0 7px;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-size: 1.2vh;
  text-transform: uppercase;
  width: 80%;
  height: 100%;
}

.page-print-page2-radar-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  height: 50%;
}

.page-print-page2-radar-img img {
  height: 100%;
}

.page-print-page2-radar-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.page-print-page-radar-chart-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
}

.mostrar-svg {
  position: absolute;
  z-index: 5;
  width: 10%;
  height: 10%;
}

.page-print-page3 {
  display: flex;
  flex-direction: column;
}

.page-print-page3-ods {
  border: 2px solid #a3a3a3;
  box-sizing: border-box;
  border-radius: 21.7826px;
}

.page-print-page3-ods-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.page-print-page3-ods-card {
  border-color: #ffff;
  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  margin: 2vh 1vw;
  width: 30%;
}

.page-print-page3-ods-card-breakdown {
  margin-top: 30vh;
}

.page-print-page3-ods-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f7f9fa;
  border-radius: 10px 10px 0px 0px;
  padding: 12px 16px 6px;
}

.page-print-page3-ods-text svg {
  padding: 0.5vh 1vw;
  width: 15%;
}

.page-print-page3-ods-text span {
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 3vh;
  line-height: 120%;
  text-align: center;
  color: #707780;
  width: 7%;
}

.page-print-page3-ods-text h2 {
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2vh;
  line-height: 110%;
  color: #707780;
  width: 60%;
  margin: 0vh 0vw;
  padding: 0vh 0vw 0vh 1vw;
}

.page-print-page3-ods-text p {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 2.3vh;
  line-height: 120%;
  color: #707780;
  width: 18%;
  margin: 0vh 0vw;
  padding: 0vh 0vw 0vh 0.5vh;
}

.page-print-page3-ods-bar {
  height: 5px;
}

.page-print-page3-ods-desc h2 {
  border-color: #e6e9ed;
  border-radius: 0px 0px 10px 10px;
  border-style: solid;
  border-width: 3px;
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 133%;
  text-align: center;
  color: #666666;
  margin: 0vh 0vw;
  padding: 1vh 2vw;
  min-height: 10vh;
}

@media screen and (max-width: 1025px) {
  html,
  body {
    border: 0vh 0vw;
    height: 10%;
    margin: 0vh 0vw;
    padding: 0vh 0vw;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-map {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .div-map-header-links {
    text-decoration: none;
    padding: 3vh 0vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .div-map-header-photo {
    justify-content: center;
    width: 100%;
  }

  .div-map-header-photo img {
    border-radius: 100%;
    overflow: hidden;
    height: 10%;
    width: 10%;
  }

  .div-map-header-img img {
    width: 40vw;
  }

  .footer-map {
    margin: 0vh 0vw;
    padding: 0vh 0vw;
  }

  .div-map-footer-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1vh 0vw;
    margin: 0vh 0vw;
  }

  .div-map-footer-button .material-icons {
    color: #2f1764;
  }

  .map-footer-button-back {
    color: #2f1764;
    background-color: #ffffff;
    border-color: #2f1764;
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 1vh 0vw;
    padding: 0vh 1vw;
    width: 60vw;
  }

  .map-footer-button {
    color: #ffffff;
    background-color: #2f1764ff;
    border-color: #2f1764;
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 1vh 0vw;
    padding: 0.5vh 3vw;
    width: 75vw;
  }

  .mostrar-svg {
    position: absolute;
    z-index: 5;
    width: 20%;
  }
}
</style>